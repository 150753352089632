.parentDivCat {
  width: 800px;
}

.backAnimation {
  position: absolute;
  background-color: #f26522;
  height: 100%;
  width: 25%;
  transition: left 0.5s;
  color: #752f28;
  &::before {
    content: " ";
  }
}

.animationTab1 {
  left: 0%;
}
.animationTab2 {
  left: 25%;
}
.animationTab3 {
  left: 50%;
}
.animationTab4 {
  left: 75%;
}

.categoryDiv {
  position: relative;
  display: flex;
  width: 100%;
  margin: 45px 0px;
  // border: 2px solid #ebdad0;
  border-radius: 15px;
  background-color: #feebd2;
  overflow: hidden;
  color: #752f28;

  .categoryType {
    color: inherit;
    width: 25%;
    text-align: center;
    padding: 15px 30px;
    font-size: 24px;
    font-weight: 600;
    background-color: transparent;
    z-index: 2;
    &:hover {
      // color: white;
      // background-color: #f26522;
      cursor: pointer;
    }
    &:nth-child(2) {
      border-right: 2px solid #ebdad0;
    }
    &:last-child {
      border-left: 2px solid #ebdad0;
    }
    &:nth-child(4) {
      border-left: 2px solid #ebdad0;
    }
  }
}

.categoryTypeActive {
  color: white !important;
}

.listDiv {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 50px;
  .listItem {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0px;
    .listImg {
      height: 100px;
      width: 100px;
      margin-bottom: 10px;
      border-radius: 100%;
    }
    .listName {
      font-size: 16px;
      font-weight: 600;
      color: #752f28;
    }
  }
}
