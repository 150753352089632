//-----navbar-----//
$baseColor: #f26522;
$secondaryColor: #f8991d;
$topNavTextColor: #000000;
$bottomNavtextColor: #ffffff;
$navElementBorderColor: #956a33;
$navElementHoverColor: #40102e;
$mobileNavElementBackground: #fae1cb;
$mobileNavInnerElementBackground: #fef4eb;

//-----slider-----//
$baseDotColor: #fd5602;
$activeDotColor: #9a031e;

//------home/basic page button-----------//
$buttonBackgroundColor: #752f28;
$buttonHoverBackgroundColor: #fb8b24;
$buttonHoverTextColor: #181818;
$buttonTextColor: #ffffff;

//breadcrumb
$breadcrumbTextColor: #620404;
$headingsColor: #ffaf42;

//-----home page variables ----//
$servicesBackground: #ffffff;
$happeningsBackground: #ffffff;
$headingTextColor: #752f28;
$newsBarColor: #620404;

//-----footer varibales----//
$footerTopBackground: #f8991d;
$footerMiddleBackground: #752f28;
$footerBottomBackground: #57221d;
