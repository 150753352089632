p {
  margin: 0;
}
.csoon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 50vh;
  .comingSoon {
    color: #999999;
    font-size: 30px;
    font-weight: 600;
  }
  .home {
    font-size: 18px;
  }
  a {
    color: #9a031e;
    cursor: pointer;
  }
  a:hover {
    font-weight: 600;
  }
}
.videoDiv {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
}
.joinvideoDiv {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  overflow: hidden;
  background: #feebdb;
  .thumbnail {
    width: 100%;
    background: url(../../Assets/devasthanam-default-banner.svg);
    background-position: center;
    margin: 0;
  }
}
.stream {
  border: 1px solid #cbcbcb;
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  .s-left {
    padding: 20px 20px;

    width: 70%;
    background: #efefef 0% 0% no-repeat padding-box;
    video {
      width: 100%;
      margin-top: 15px;
    }
    .controls {
      display: flex;
      justify-content: center;
      align-items: center;
      div {
        width: 70px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0px 20px;
        img {
          height: 60px;
          width: 32px;
        }
      }
    }
  }
  .s-right {
    padding: 20px 0px;
    background-color: #feebd2;

    width: 30%;
    max-height: 70vh;

    .pooja-box {
      height: 100%;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

.templeName {
  display: flex;
  width: 100%;
  padding: 20px 10px;
  border-bottom: 2px solid #cbcbcb;
  align-items: center;
  img {
    width: 60px;
    height: 60px;
    margin-right: 20px;
    border: 1px solid #f26522;
    border-radius: 100%;
  }
  p {
    font-size: 20px;
    color: #752f28;
    margin: 0;
    font-weight: bold;
    width: 80%;
  }
  .optionDiv {
    width: 10%;
    border-radius: 100%;
    background-color: #cbcbcb;
  }
}
.no-wrap {
  flex-wrap: nowrap !important;
}
.poojadiv {
  display: flex;
  width: 100%;
  padding: 20px 10px;
  border-bottom: 1px solid #cbcbcb;
  align-items: center;
  justify-content: space-between;
  img {
    width: 60px;
    height: 60px;
    border: 1px solid #f26522;
    border-radius: 100%;
    margin-right: 15px;
  }
  p {
    font-size: 17px;
    color: #7b7b7b;
    font-weight: bold;
    margin: 0;
    width: 70%;
  }
  .watchDiv {
    width: fit-content;
    display: flex;
    flex-direction: row;
    .watchButton {
      display: flex;
      align-items: center;
      padding-right: 30px;
      width: 100%;
      img {
        width: 24px;
        height: 24px;
        border-radius: 0%;
        border: none;
        margin-right: 5px;
      }
      p {
        font-size: 20px;
        margin: 0;
      }
    }
  }
}

.poojadiv:hover {
  background-color: #e4914e !important;
  cursor: pointer;
  img {
    filter: grayscale(0);
  }
  p {
    color: #752f28;
  }
}

.c-selected {
  p {
    color: #752f28;
  }
  background-color: #e4914e !important;
}

.sevaDivCard {
  background-color: #d1d1d1;
  display: flex;
  justify-content: space-between;
  p {
    color: #000000;
    font-size: 10px;
    margin-bottom: 3px;
  }
  .right {
    width: 50%;
    padding: 7px 15px;
  }
  .left {
    width: 50%;
    padding: 7px 15px;
    border-right: 1px solid white;
  }
}

.templesdiv {
  display: flex;
  justify-content: center;

  .temple1div {
    width: 25%;
    background: white;
    color: black;
    padding: 25px 60px;

    .title p {
      text-align: left;
      font-weight: 600;
      font-size: 30px;
      font-family: Proxima Nova Alt;
      letter-spacing: 0px;
      color: #000000;
      opacity: 1;
      margin-bottom: 20px;
    }

    .templeFilter {
      background: #f5f5f5 0% 0% no-repeat padding-box;
      border-radius: 6px;
      opacity: 1;
      padding: 22px;
      margin-bottom: 20px;

      .filterHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        .filterTitle {
          text-align: left;
          letter-spacing: 0px;
          color: #8d1c24;
          opacity: 0.9;
          font-size: 18px;
        }
        .clear {
          text-align: left;
          font-size: 14px;
          letter-spacing: 0px;
          color: #666666;
          opacity: 0.9;
          cursor: pointer;
        }
      }

      .filterButtons {
        .black {
          border: 1px solid #cccccc;
          opacity: 1;
          font-weight: 600;
          font-size: 12px;
          letter-spacing: 0px;
          color: #000000;
          margin: 5px 5px 5px 0px;
          padding: 5px;
          border-radius: 6px;
          text-align: left;
        }
        .red {
          border: 1px solid #cccccc;
          opacity: 1;
          font-weight: 600;
          font-size: 12px;
          letter-spacing: 0px;
          color: #8d1c24;
          margin: 5px 5px 5px 0px;
          padding: 5px;
          border-radius: 6px;
          text-align: left;
        }
        label {
          text-align: left;
          font-size: 16px;
          letter-spacing: 0px;
          color: #000000;
          font-weight: 400;
          margin-bottom: 10px;
        }
        select {
          border-radius: 6px;
          opacity: 1;
          background: white;
          margin-bottom: 20px;
          font-size: 16px;
          width: 100%;
          border: none;
          height: 30px;
          text-transform: capitalize;
        }
      }
    }
  }
  .temple2div {
    width: 75%;
    background: white;
    color: black;
    padding: 25px 60px 25px 25px;

    .title p {
      text-align: left;
      font-weight: 600;
      font-size: 30px;
      font-family: Proxima Nova Alt;
      letter-spacing: 0px;
      color: #000000;
      opacity: 1;
      margin-bottom: 20px;
    }

    .filterAlpha {
      display: flex;
      font-size: 12px;
      margin-bottom: 5px;
      width: 100%;
      flex-wrap: wrap;

      .alpahabets {
        border-radius: 15px;
        opacity: 1;
        border: 1px solid #cccccc;
        cursor: pointer;
        margin-right: 5px;
        margin-bottom: 5px;
        align-items: center;
        align-content: center;
        justify-content: center;
        padding-top: 2px;
        width: 32px;
        display: flex;
        height: 19px;
        p {
          margin: 0 !important;
          color: black;
          font-weight: 600;
          font-size: 12px;
        }
      }

      .alpahabets:hover {
        background: #8d1c24 0% 0% no-repeat padding-box;
        border: 1px solid #8d1c24;

        p {
          margin: 0 !important;
          color: white !important;
          font-weight: 600;
          font-size: 12px;
        }
      }

      .alpahabetsActive {
        border-radius: 15px;
        opacity: 1;
        background: #8d1c24 0% 0% no-repeat padding-box;
        border: 1px solid #8d1c24;
        cursor: pointer;
        margin-right: 5px;
        margin-bottom: 5px;
        align-items: center;
        align-content: center;
        justify-content: center;
        padding-top: 2px;
        width: 32px;
        display: flex;
        height: 19px;

        p {
          margin: 0 !important;
          color: white;
          font-weight: 600;
          font-size: 12px;
        }
      }
    }

    .serachDiv {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;
      flex-direction: row-reverse;
      .search {
        background-color: #f5f5f5;
        height: 47px;
        position: relative;
        width: 60%;
        border-radius: 5px;
        margin-right: 12px;

        input {
          width: 100%;
          display: block;
          height: 47px;
          background: transparent;
          border: none;
          font-size: 20px;
          font-weight: 600;
          color: black !important;
          padding: 10px 20px;
        }

        button {
          cursor: pointer;
          display: inline-block;
          font-size: 20px;
          position: absolute;
          top: 10px;
          right: 20px;
          border: none;
          background: transparent;
        }
      }
      .count {
        p {
          color: #333333;
          font-size: 14px;
          font-weight: 600;
          display: flex;
          align-items: center;

          select {
            border-radius: 5px;
            border: 2px solid #dcdcdc;
            background: #ffffff;
            padding: 8px 17px;
            color: #333333;
            font-size: 14px;
            margin: 0px 6px;
          }
        }
      }
    }

    .templesDiv {
      display: flex;
      width: 100%;
      flex-wrap: wrap;

      .templeCard {
        border: 1px solid #dcdcdc;
        background: #ffffff;
        border-radius: 20px;
        max-width: calc(31.9% - 4px);
        min-width: calc(31.9% - 4px);
        margin-bottom: 20px;
        margin-top: 20px;
        &:nth-child(3n) {
          margin-left: 24px;
        }
        &:nth-child(3n + 1) {
          margin-right: 24px;
        }

        .cardImg {
          border-radius: 20px 20px 0px 0px;
          overflow: hidden;
          img {
            height: 290px;
            object-fit: cover;
            object-position: center;
          }
        }

        .cardDesc {
          padding: 15px 18px;
          .cardText {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 10px;
            align-items: center;
            min-height: 38px;

            .cardTitle {
              display: flex;
              justify-content: flex-start;
              align-items: center;

              img {
                width: 30px;
                height: 30px;
                margin-right: 10px;
                border-radius: 50px;
                object-fit: cover;
              }

              p {
                font-size: 16px;
                color: #171717;
                line-height: 19px;
                margin-bottom: 0;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }
            }
          }
          .tempfullname {
            margin-bottom: 10px !important;
            font-size: 14px;
            color: #171717;
            font-weight: 600;
            line-height: 19px;
            min-height: 38px;
            align-items: center;
            display: flex;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .tempDesc {
            margin-bottom: 20px !important;
            font-size: 13px;
            color: #666666;
            line-height: 15px;
            min-height: 45px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
          .cardBottom {
            display: flex;
            justify-content: space-between;
            align-items: center;

            img {
              margin-right: 5px;
              width: 20px;
              height: 20px;
            }

            p {
              font-size: 11px;
              color: #333333;
            }

            a {
              text-align: left;
              text-decoration: underline !important;
              letter-spacing: 0px;
              color: #8d1c24;
              opacity: 1;
              font-size: 13px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  .joinPooja {
    width: 90%;
    background: white;
    color: black;
    padding: 25px;

    .title p {
      text-align: left;
      font-weight: 600;
      font-size: 30px;
      font-family: Proxima Nova Alt;
      letter-spacing: 0px;
      color: #000000;
      opacity: 1;
      margin-bottom: 20px;
    }

    button {
      background-color: #752f28;
      border-radius: 6px;
      padding: 2px 5px;
      color: white;
      font-size: 12px;
      border: 0;
    }

    .sevaDivCard {
      background-color: #d1d1d1;
      display: flex;
      justify-content: space-between;
      p {
        color: #000000;
        font-size: 10px;
        margin-bottom: 3px;
      }
      .right {
        width: 40%;
        padding: 7px 15px;
        text-align: right;
      }
      .left {
        width: 60%;
        padding: 7px 15px;
        border-right: 1px solid white;
      }
    }

    .serachDiv {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;
      flex-direction: row-reverse;
      .search {
        background-color: #f5f5f5;
        height: 47px;
        position: relative;
        width: 60%;
        border-radius: 5px;
        margin-right: 12px;

        input {
          width: 100%;
          display: block;
          height: 47px;
          background: transparent;
          border: none;
          font-size: 20px;
          font-weight: 600;
          color: black !important;
          padding: 10px 20px;
        }

        button {
          cursor: pointer;
          display: inline-block;
          font-size: 20px;
          position: absolute;
          top: 10px;
          right: 20px;
          border: none;
          background: transparent;
        }
      }
      .count {
        p {
          color: #333333;
          font-size: 14px;
          font-weight: 600;
          display: flex;
          align-items: center;

          select {
            border-radius: 5px;
            border: 2px solid #dcdcdc;
            background: #ffffff;
            padding: 8px 17px;
            color: #333333;
            font-size: 14px;
            margin: 0px 6px;
          }
        }
      }
    }

    .templesDiv {
      display: flex;
      width: 100%;
      flex-wrap: wrap;

      .templeCard {
        border: 1px solid #dcdcdc;
        background: #ffffff;
        border-radius: 20px;
        max-width: calc(31.9% - 4px);
        min-width: calc(31.9% - 4px);
        margin-bottom: 20px;
        margin-top: 20px;
        &:nth-child(3n) {
          margin-left: 24px;
        }
        &:nth-child(3n + 1) {
          margin-right: 24px;
        }

        .cardImg {
          border-radius: 20px 20px 0px 0px;
          img {
            height: 290px;
          }
        }

        .cardDesc {
          padding: 15px 18px;
          .cardText {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 10px;
            align-items: center;

            .cardTitle {
              display: flex;
              justify-content: flex-start;
              align-items: center;

              img {
                width: 18px;
                height: 18px;
                margin-right: 10px;
              }

              p {
                font-size: 14px;
                color: #171717;
                line-height: 19px;
              }
            }
          }
          .tempfullname {
            margin-bottom: 10px !important;
            font-size: 14px;
            color: #752f28;
            font-weight: 600;
            line-height: 19px;
            min-height: 38px;
            align-items: center;
            display: flex;
          }
          .tempDesc {
            font-size: 13px;
            font-weight: 600;
            color: #222222;
            line-height: 15px;
            margin-bottom: 10px;
          }
          .cardBottom {
            display: flex;
            justify-content: space-between;
            align-items: center;

            img {
              margin-right: 5px;
              width: 20px;
              height: 20px;
            }

            p {
              font-size: 11px;
              color: #333333;
            }

            a {
              text-align: left;
              text-decoration: underline !important;
              letter-spacing: 0px;
              color: #8d1c24;
              opacity: 1;
              font-size: 13px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1800px) and (min-width: 1700px) {
  .cardImg {
    img {
      height: 280px !important;
    }
  }
}
@media screen and (max-width: 1700px) and (min-width: 1600px) {
  .cardImg {
    img {
      height: 270px !important;
    }
  }
}
@media screen and (max-width: 1600px) and (min-width: 1500px) {
  .temple1div {
    width: 30% !important;
  }
  .temple2div {
    width: 70% !important;
  }
  .cardImg {
    img {
      height: 240px !important;
    }
  }
}
@media screen and (max-width: 1500px) and (min-width: 1400px) {
  .temple1div {
    width: 30% !important;
  }
  .temple2div {
    width: 70% !important;
  }
  .cardImg {
    img {
      height: 230px !important;
    }
  }
}
@media screen and (max-width: 1400px) and (min-width: 1318px) {
  .temple1div {
    width: 30% !important;
  }
  .temple2div {
    width: 70% !important;
  }
  .cardImg {
    img {
      height: 210px !important;
    }
  }
  .videoDiv {
    p {
      font-size: 20px;
    }
  }
}
@media screen and (max-width: 1318px) and (min-width: 1200px) {
  .temple1div {
    width: 35% !important;
  }
  .temple2div {
    width: 65% !important;
    .templeCard {
      max-width: calc(30.9% - 4px) !important;
      min-width: calc(30.9% - 4px) !important;
      .cardImg {
        img {
          height: 190px !important;
        }
      }
    }
  }
  .videoDiv {
    p {
      font-size: 20px;
    }
  }
}

@media screen and (max-width: 1200px) and (min-width: 800px) {
  .stream {
    flex-direction: column-reverse;
    .s-right {
      width: 100%;
      max-height: 50vh;
      overflow-y: scroll;
    }
    .s-left {
      width: 100%;
    }
  }
  .temple1div {
    width: 35% !important;
  }
  .temple2div {
    width: 65% !important;
    .templeCard {
      border: 1px solid #dcdcdc;
      background: #ffffff;
      border-radius: 20px;
      max-width: calc(48% - 4px) !important;
      min-width: calc(48% - 4px) !important;
      margin: 20px 0px;
      &:nth-child(2n) {
        margin-left: 10px !important;
        margin-right: 0px !important ;
      }
      &:nth-child(2n + 1) {
        margin-right: 10px !important;
        margin-left: 0px !important ;
      }
      .cardImg {
        img {
          height: 200px !important;
        }
      }
    }
  }
  .videoDiv {
    p {
      font-size: 18px;
    }
  }
  .poojadiv p {
    width: 55%;
  }
  .watchDiv {
    width: 35% !important;
  }
  .poojadiv .watchDiv .watchButton p {
    font-size: 14px;
  }
}

@media screen and (max-width: 800px) and (min-width: 600px) {
  .stream {
    flex-direction: column-reverse;
    .s-right {
      width: 100%;
      max-height: 50vh;
      overflow-y: scroll;
    }
    .s-left {
      width: 100%;
    }
  }
  .templesdiv {
    flex-direction: column !important;
  }
  .temple1div {
    width: 100% !important;
    padding: 25px 40px !important;
  }
  .temple2div {
    width: 100% !important;
    .templeCard {
      max-width: calc(48% - 4px) !important;
      min-width: calc(48% - 4px) !important;
      margin-bottom: 10px !important;
      margin-top: 10px !important;
      &:nth-child(2n) {
        margin-left: 10px !important;
        margin-right: 0px !important ;
      }
      &:nth-child(2n + 1) {
        margin-right: 10px !important;
        margin-left: 0px !important ;
      }
      .tempfullname {
        min-height: 57px !important;
      }
      .cardImg {
        img {
          height: 195px !important;
        }
      }
    }
  }
  .videoDiv {
    p {
      font-size: 16px !important;
    }
  }
  .poojadiv p {
    width: 55%;
  }
  .watchButton {
    width: 100% !important;
    padding-bottom: 10px;
    padding-right: 0 !important;
  }
  .watchDiv {
    width: 35% !important;
    flex-direction: column !important;
  }
  .poojadiv .watchDiv .watchButton p {
    font-size: 14px;
  }
}

@media screen and (max-width: 600px) and (min-width: 550px) {
  .stream {
    flex-direction: column-reverse;
    .s-right {
      width: 100%;
      max-height: 50vh;
      overflow-y: scroll;
    }
    .s-left {
      width: 100%;
    }
  }
  .templesdiv {
    flex-direction: column !important;
  }
  .temple1div {
    width: 100% !important;
    padding: 25px 40px !important;
  }
  .temple2div {
    width: 100% !important;
    .templeCard {
      max-width: calc(48% - 4px) !important;
      min-width: calc(48% - 4px) !important;
      margin-bottom: 10px !important;
      margin-top: 10px !important;
      &:nth-child(2n) {
        margin-left: 10px !important;
        margin-right: 0px !important ;
      }
      &:nth-child(2n + 1) {
        margin-right: 10px !important;
        margin-left: 0px !important ;
      }
      .tempfullname {
        min-height: 57px !important;
      }
      .cardImg {
        img {
          height: 195px !important;
        }
      }
    }
  }
  .templeName img {
    width: 40px !important;
    height: 40px !important;
  }
  .poojadiv img {
    width: 40px !important;
    height: 40px !important;
  }
  .videoDiv {
    p {
      font-size: 16px !important;
    }
  }
  .poojadiv p {
    width: 55%;
  }
  .watchButton {
    width: 100% !important;
    padding-bottom: 10px;
    padding-right: 0 !important;
  }
  .watchDiv {
    width: 35% !important;
    flex-direction: column !important;
  }
  .poojadiv .watchDiv .watchButton p {
    font-size: 14px;
  }
  .poojadiv .watchDiv .watchButton img {
    width: 24px !important;
    height: 24px !important;
  }
}

@media screen and (max-width: 550px) {
  .stream {
    flex-direction: column-reverse;
    .s-right {
      width: 100%;
      max-height: 50vh;
      overflow-y: scroll;
      margin-bottom: 10px;
    }
    .s-left {
      width: 100%;
    }
  }
  .templesdiv {
    flex-direction: column !important;
  }
  .temple1div {
    width: 100% !important;
    padding: 25px 40px !important;
  }
  .temple2div {
    width: 100% !important;
    .templeCard {
      max-width: calc(98% - 4px) !important;
      min-width: calc(98% - 4px) !important;
      margin-bottom: 10px !important;
      margin-top: 10px !important;
      &:nth-child(2n) {
        margin-left: 0px !important;
        margin-right: 0px !important ;
      }
      &:nth-child(2n + 1) {
        margin-right: 0px !important;
        margin-left: 0px !important ;
      }
      .tempfullname {
        min-height: 57px !important;
      }
      .cardImg {
        img {
          height: 195px !important;
        }
      }
    }
    .serachDiv {
      flex-direction: column !important;
      .search {
        width: 100% !important;
        margin-bottom: 10px;
      }
    }
  }
  .templesdiv .joinPooja {
    padding: 0 !important;
  }

  .templeName img {
    width: 40px !important;
    height: 40px !important;
  }
  .poojadiv img {
    width: 40px !important;
    height: 40px !important;
  }
  .videoDiv {
    p {
      font-size: 16px !important;
    }
  }
  .poojadiv p {
    width: 100% !important;
  }
  .watchButton {
    width: 50% !important;
    padding-top: 10px;
    padding-bottom: 0px;
    padding-right: 0 !important;
    align-items: center !important;
    justify-content: center !important;
    p {
      width: fit-content !important;
    }
  }
  .watchDiv {
    width: 100% !important;
    flex-direction: row !important;
  }
  .poojadiv .watchDiv .watchButton p {
    font-size: 14px;
  }
  .poojadiv .watchDiv .watchButton img {
    width: 24px !important;
    height: 24px !important;
  }
  .poojadiv {
    flex-wrap: wrap;
  }
}
